<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Aset Belum Bersertifikat </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateAsetunregis">
            <CRow>
              <CCol sm="6">
                <CInput
                  v-model="kode_skpd"
                  label="Kode SKPD"
                  placeholder="-"
                  readonly
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nama_skpd"
                  label="Nama SKPD"
                  placeholder="-"
                  
                />
              </CCol>
              
              <CCol sm="6">
                <CInput
                  v-model="kabupaten_kota"
                  label="Kota/Kabupaten"
                  placeholder="-"
                  readonly
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="kode_lokasi"
                  label="Kode Lokasi"
                  placeholder="-"
                  readonly
                />
              </CCol>

              <CCol sm="6">
                <CInput
                  v-model="nama_instansi"
                  label="Nama OPD"
                  placeholder="-"
                  
                />
              </CCol>

              <CCol sm="6">
                <CInput
                  v-model="kode_barang"
                  label="Kode Barang"
                  placeholder="-"
                  readonly
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nama_barang"
                  label="Nama Barang"
                  placeholder="-"
                  
                />
              </CCol>

              <CCol sm="3">
                <CInput
                  v-model="no_hak"
                  label="Nomor HAK"
                  placeholder="-"
                  
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="jenis_hak"
                  label="Tipe HAK"
                  placeholder="-"
                  
                />
              </CCol>
              <CCol sm="3">
                <CInput                  
                  v-model="tgl_perolehan"
                  label="Tanggal Perolehan"
                  placeholder="-"
                  readonly
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="nilai_perolehan"
                  label="Harga Perolehan"
                  placeholder="-"
                  
                />
              </CCol>

              <CCol sm="3">
                <CInput
                  v-model="luas_tertulis"
                  label="Luas (m2)"
                  placeholder="-"
                  
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  
                  v-model="tgl_sertifikat"
                  label="Tanggal Sertifikat"
                  placeholder="-"
                  readonly
                />
              </CCol> 
              
              <CCol sm="12">
                <CInput
                  v-model="letak_lokasi"
                  label="Letak/Alamat"
                  placeholder="Input letak/alamat"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="kecamatan"
                  label="Kecamatan"
                  placeholder="Input kecamatan"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="kelurahan"
                  label="Kelurahan"
                  placeholder="Input kelurahan"
                />
              </CCol>

              <CCol sm="6">
                <div class="form-group">
                  <label> Sertifikat <a href='' target='_blank'>[Download]</a></label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange1($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label> Foto Lokasi <a href='' target='_blank'>[Download]</a></label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder2" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder2}}</label>
                  </div>
                </div>  
              </CCol>
              
              <!-- <CCol sm="6">
                <div class="form-group">
                  <label> Pagar <a href='' target='_blank'>[Download]</a></label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange3($event)" id="inputGroupFile03"
                    :placeholder="placeholder3" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder3}}</label>
                  </div>
                </div>  
              </CCol> 
              <CCol sm="6">
                <div class="form-group">
                  <label> Plang Tanah <a href='' target='_blank'>[Download]</a></label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange4($event)" id="inputGroupFile04"
                    :placeholder="placeholder4" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder4}}</label>
                  </div>
                </div>  
              </CCol>  -->
              <CCol sm="6">
                <div class="form-group">
                  <label>Alas Hak <a href='' target='_blank'>[Download]</a></label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange5($event)" id="inputGroupFile05"
                    :placeholder="placeholder5" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder5}}</label>
                  </div>
                </div>  
              </CCol> 
               
              <CCol sm="6">
                <div class="form-group">
                  <label> File Buku Tanah <a href='' target='_blank'>[Download]</a></label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange6($event)" id="inputGroupFile06"
                    :placeholder="placeholder6" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder6}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Dikuasai </label>
                  <select v-model="dikuasai" placeholder="Pilihan" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="Dikuasai Masyarakat">Dikuasai Masyarakat</option>
                    <option value="Clean & Clear">Clean & Clear</option>
                  </select>
                </div>
              </CCol>

              <CCol sm="12">
              <CTextarea
                v-model="isi_catatan_utk_bpn"
                label="Catatan untuk Petugas BPN"
                placeholder="Input Catatan"
              />
              </CCol>
              <!-- <CCol sm="6">             
                <div class="form-group">
                <label>Nama Petugas</label>
                  <select class="form-control" v-model="nickname">
                    <option value="" disabled selected>-- Pilih Nama --</option>
                    <option v-for="r in nicknames" :value="r.id_user">{{r.nickname}} </option>
                  </select>
                </div>
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="tgl_catatan_utk_bpn"
                label="Tanggal"
                placeholder="Input Tanggal"
              />
              </CCol> -->

            </CRow>           
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/asetunregis">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
      
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      isi_catatan_utk_bpn: "",
      tgl_catatan_utk_bpn: "",
      nickname: "",
      nicknames: [],

      kode_skpd : "",
      nama_skpd : "",
      kabupaten_kota : "",
      kode_lokasi : "",
      nama_instansi : "",
      letak_lokasi : "",
      kode_barang : "",
      nama_barang : "",
      no_hak : "",
      jenis_hak : "",
      luas_tertulis : "",
      tgl_sertifikat : "",
      kecamatan : "",
      kelurahan : "",

      sertifikat : "",
      sertifikat_text : "",
      foto_lokasi : "",
      foto_lokasi_text : "",
      pagar : "",
      pagar_text : "",
      plang_tanah : "",
      plang_tanah_text : "",
      alas_hak : "",
      alas_hak_text : "",
      buku_tanah : "",
      buku_tanah_text : "",
      dikuasai : "",
      placeholder : "Pilih File",
      placeholder2 : "Pilih File",
      placeholder3 : "Pilih File",
      placeholder4 : "Pilih File",
      placeholder5 : "Pilih File",
      placeholder6 : "Pilih File",
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showAsetunregis();
  this.loadData();
  },

  methods: {    
    onFileChange1(event) {
      var fileData = event.target.files[0];
      this.sertifikat_text = fileData.name;
      this.sertifikat = fileData;
      this.placeholder = fileData.name;
    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.foto_lokasi_text = fileData.name;
      this.foto_lokasi = fileData;
      this.placeholder2 = fileData.name;
    },
    onFileChange3(event) {
      var fileData = event.target.files[0];
      this.pagar_text = fileData.name;
      this.pagar = fileData;
      this.placeholder3 = fileData.name;
    },
    onFileChange4(event) {
      var fileData = event.target.files[0];
      this.plang_tanah_text = fileData.name;
      this.plang_tanah = fileData;
      this.placeholder4 = fileData.name;
    },
    onFileChange5(event) {
      var fileData = event.target.files[0];
      this.alas_hak_text = fileData.name;
      this.alas_hak = fileData;
      this.placeholder5 = fileData.name;
    },
    onFileChange6(event) {
      var fileData = event.target.files[0];
      this.buku_tanah_text = fileData.name;
      this.buku_tanah = fileData;
      this.placeholder6 = fileData.name;
    },
    loadData: function(event){

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"nickname/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              this.nicknames=response.data;
        });
    },
    
    updateAsetunregis: function(event){

      let us = JSON.parse(this.$store.state.users);
      
      const formData = new FormData()

      formData.append('id_aset_bersertifikat', this.$route.params.id_aset_bersertifikat) 
      formData.append('nama_skpd', this.nama_skpd)
      formData.append('nama_skpd2', this.nama_skpd2)
      formData.append('nama_instansi', this.nama_instansi)
      formData.append('nama_instansi2', this.nama_instansi2)
      formData.append('nama_barang', this.nama_barang)
      formData.append('no_hak', this.no_hak)
      formData.append('jenis_hak', this.jenis_hak)
      formData.append('jenis_hak2', this.jenis_hak2)
      formData.append('letak_lokasi', this.letak_lokasi)
      formData.append('nilai_perolehan', this.nilai_perolehan)
      formData.append('nilai_perolehan2', this.nilai_perolehan2)
      formData.append('luas_tertulis', this.luas_tertulis)
      formData.append('luas_tertulis2', this.luas_tertulis2)
      formData.append('kecamatan', this.kecamatan)
      formData.append('kelurahan', this.kelurahan)
      formData.append('dikuasai', this.dikuasai)

      formData.append('isi_catatan_utk_bpn', this.isi_catatan_utk_bpn)
      formData.append('id_user', us.id_user)
      // formData.append('tgl_catatan_utk_bpn', this.tgl_catatan_utk_bpn)

      if(this.sertifikat.name){
        formData.append('sertifikat', this.sertifikat, this.sertifikat.name)
      }
      if(this.foto_lokasi.name){
        formData.append('foto_lokasi', this.foto_lokasi, this.foto_lokasi.name)
      }
      if(this.pagar.name){
        formData.append('pagar', this.pagar, this.pagar.name)
      }
      if(this.plang_tanah.name){
        formData.append('plang_tanah', this.plang_tanah, this.plang_tanah.name)
      }
      if(this.alas_hak.name){
        formData.append('alas_hak', this.alas_hak, this.alas_hak.name)
      }
      if(this.buku_tanah.name){
        formData.append('buku_tanah', this.buku_tanah, this.buku_tanah.name)
      }

      axios.post(process.env.VUE_APP_BASE_URL+"updateasetbelumbersertifikat", formData)
        .then((response) => {
            
            if(response.status==200){
              this.$router.push('/asetunregis');
            }
            else{
              this.modalError = true;
            }
        })
    },

    showAsetunregis: function(event){
      // Simple POST request with a JSON body using axios
      const asetunregis = { 
                          kode_skpd : this.kode_skpd,
                          nama_skpd : this.nama_skpd,
                          kabupaten_kota : this.kabupaten_kota,
                          kode_lokasi : this.kode_lokasi,                          
                          nama_instansi: this.nama_instansi, 
                          kode_barang : this.kode_barang,
                          nama_barang : this.nama_barang,
                          no_hak: this.no_hak,
                          jenis_hak: this.jenis_hak,
                          tgl_perolehan: this.tgl_perolehan,
                          nilai_perolehan: this.nilai_perolehan,
                          luas_tertulis: this.luas_tertulis,
                          tgl_sertifikat: this.tgl_sertifikat,
                          kecamatan: this.kecamatan,
                          kelurahan: this.kelurahan,
                          letak_lokasi: this.letak_lokasi,                                                    
                          dikuasai: this.dikuasai,

                          sertifikat: this.sertifikat,
                          buku_tanah: this.buku_tanah,                          
                          alas_hak: this.alas_hak,
                          foto_lokasi: this.foto_lokasi
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"editasetbelumbersertifikat/"+this.$route.params.id_aset_bersertifikat)
        .then((response) => {
            
              this.kode_skpd=response.data.kode_skpd;
              this.nama_skpd=response.data.nama_skpd;
              this.nama_skpd2=response.data.nama_skpd;
              this.kabupaten_kota=response.data.kabupaten_kota;
              this.kode_lokasi=response.data.kode_lokasi;
              this.nama_instansi=response.data.nama_instansi;
              this.nama_instansi2=response.data.nama_instansi;
              this.kode_barang=response.data.kode_barang;
              this.nama_barang=response.data.nama_barang;
              this.no_hak=response.data.no_hak;
              this.jenis_hak=response.data.jenis_hak;
              this.jenis_hak2=response.data.jenis_hak;
              this.tgl_perolehan=response.data.tgl_perolehan;
              this.nilai_perolehan=response.data.nilai_perolehan;  
              this.nilai_perolehan2=response.data.nilai_perolehan;  
              this.luas_tertulis=response.data.luas_tertulis;       
              this.luas_tertulis2=response.data.luas_tertulis;       
              this.tgl_sertifikat=response.data.tgl_sertifikat;             
              this.kecamatan=response.data.kecamatan;            
              this.kelurahan=response.data.kelurahan;            
              this.letak_lokasi=response.data.letak_lokasi;            
              this.dikuasai=response.data.dikuasai;            

              this.placeholder=response.data.sertifikat;            
              this.placeholder2=response.data.foto_lokasi;            
              // this.placeholder3=response.data.pagar;            
              // this.placeholder4=response.data.plang_tanah;            
              this.placeholder5=response.data.alas_hak;            
              this.placeholder6=response.data.buku_tanah;            
        })
    }
  }
}
</script>